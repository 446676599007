import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview } from '../index';

const NSCLC_1L_COMBO_SQ = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview 
            title="Study Overview<sup>1</sup>" 
            description="Phase 3, randomized, multicenter, double-blind, placebo-controlled trial. Enrolled systemic therapy–naïve patients with metastatic squamous NSCLC, regardless of PD&#8288;-&#8288;L1 tumor expression status. Patients with autoimmune disease that required systemic therapy within 2 years of treatment; a medical condition that required immunosuppression; or patients who had received more than 30 Gy of thoracic radiation within the prior 26 weeks were ineligible. Patients were randomized to receive KEYTRUDA 200&nbsp;mg every 3 weeks (Q3W), carboplatin Q3W, and either paclitaxel Q3W or paclitaxel protein&#8288;-&#8288;bound every 1 week (Q1W) intravenously for four 3-week cycles followed by KEYTRUDA 200&nbsp;mg Q3W (n=278); or carboplatin Q3W and either paclitaxel Q3W or paclitaxel protein&#8288;-&#8288;bound Q1W intravenously for four 3-week cycles followed by placebo Q3W (n=281). Treatment continued until progression of disease, unacceptable toxicity, or up to 24 months. The main efficacy outcome measures were OS, PFS, and ORR. An additional efficacy outcome measure was duration of response (DOR). PFS, ORR, and DOR were assessed by blinded independent central review (BICR) using Response Evaluation Criteria In Solid Tumors v1.1 (RECIST v1.1) (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ). Patients receiving carboplatin and either paclitaxel or paclitaxel protein&#8288;-&#8288;bound alone who experienced disease progression could cross over to receive KEYTRUDA as monotherapy."
        />
    </ComponentWrapper>
);

export default NSCLC_1L_COMBO_SQ;